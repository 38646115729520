import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { MetaData } from 'components/meta'
import Twitter from 'static/share_twitter_icon.svg'
import Linkedin from 'static/share_linkedin_icon.svg'
import Facebook from 'static/share_facebook_icon.svg'
import { Layout } from 'components'
import * as s from './style.module.sass'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
  const post = data.ghostPost
  return (
    <>
      <MetaData
        data={data}
        location={location}
        type="article"
      />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
      </Helmet>
      <Layout>
        <div className="container">
          <article className="content">
            <header className="post-header">
              <h1 className="content-title">{post.title}</h1>
              <p className="post-custom-excerpt">{post.excerpt}</p>
              <div className="post-full-byline">
                <section className="post-full-byline-content">
                  <ul className="author-list">
                    <li className="author-list-item">
                      <div className="post-card-avatar">
                        {post.primary_author.profile_image ?
                          <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name} /> :
                          <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name} />
                        }
                      </div>
                    </li>
                  </ul>
                  <section className="post-full-byline-meta">
                    <h4 class="author-name">{post.primary_author.name}</h4>
                    <div>
                      <span className="byline-meta-date">{post.published_at_pretty}</span>
                      <span className="bull">•  temps de lecture : {post.reading_time}min</span>
                    </div>
                  </section>
                </section>
                <section className={s.shareSection}>
                  <a className={s.shareSocialMedia} target="_blank" data-lang="fr" data-show-count="false" target="_blank"
                    href={`https://twitter.com/intent/tweet?text=${post.title} ${process.env.GATSBY_SITE_URL}/${post.slug}`}>
                    <Twitter />
                  </a>
                  <a className={s.shareSocialMedia} data-href={`${process.env.GATSBY_SITE_URL}/${post.slug}`} data-layout="button_count" data-size="small" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_SITE_URL}/${post.slug}&quote=${post.title}`}>
                    <Facebook />
                  </a>
                  <a className={s.shareSocialMedia} target="_blank"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.GATSBY_SITE_URL}/${post.slug}`}>
                    <Linkedin />
                  </a>
                </section>

              </div>
            </header>
            <section className="post-full-content">
              <section className="author_section">
              </section>
              <section
                className="content-body load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </section>
          </article>
        </div>
      </Layout>
    </>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      slug: PropTypes.string,
      feature_image: PropTypes.string,
    }).isRequired,
    allGhostPost: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      description: PropTypes.string,
      logo: PropTypes.string,
      icon: PropTypes.string,
      cover_image: PropTypes.string,
      lang: PropTypes.string,
      navigation: PropTypes.array,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
   }
`
